.ActiveUsers {
    position: absolute;
    top: 70px;
    left: 200px;

    width: 300px;
    padding: 4px 18px;

    background:  #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(#4E28C4, .4);
    border: 1px solid rgba(#4E28C4, .3);
    text-align: center;

    &.errored {
        border-color: rgba(red, .3);

        h5 {
            color: red !important;
        }
    }

    &:before, &:after {
        content: '';
        position: absolute;
        top: -14px;
        left: 46%;
        right: 50%;
        margin: auto;

        $d: 25px;
        width: $d;
        height: $d;

        background: #fff;
        border-left: 1px solid rgba(#4E28C4, .3);
        border-top: 1px solid rgba(#4E28C4, .3);
        border-radius: 3px 0 0 0;
        transform: rotate(45deg);
    }

    &:after {
        z-index: -1;
        box-shadow: 0 0 3px rgba(#4E28C4, .4);
    }

    h5 {
        margin-top: .5em;

        color: #cecece;
        letter-spacing: 1px;
        text-align: center;
    }

    ul {
        padding: 0;
        list-style: none;
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .active-user {
        display: flex;
        width: 100%;

        & + .active-user {
            margin-top: 10px;
        }

        &--away {
            .active-user__info p { color: #aaa; }
            .active-user__photo { border-color: #aaa; }
        }

        &__photo {
            $d: 50px;
            flex: 0 1 $d;

            width: $d;
            height: $d;

            text-align: center;
            border: 3px solid #4E28C4;
            border-radius: 50%;
            overflow: hidden;
            background: #4E28C4;

            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -50%;
                right: -50%;

                min-width: 105%;
                height: 105%;
                margin: auto;
            }
        }

        &__info {
            flex: 1 1 auto;
            margin-left: 1em;

            text-align: left;
            vertical-align: center;
        }

        p {
            display: inline-block;
            position: relative;
            top: 10px;

            margin: auto;
            color: #4E28C4;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
        }
    }
}
