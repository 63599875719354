@import "src/theme";
@import "../../../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../../../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine";

.TabularView {
  grid-row-start: 4;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 4;

  margin: 10px;
  z-index: 102;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: $br;

  transition: all 0.35s ease-in-out;
  max-height: 10000px;
  max-width: 10000px;

  .ag-header-row-column {
    top: 24px !important;

    .ag-header-cell {
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) { background: #f5f5f5; }

        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) { background: #ececec; }
    }
  }

  .ag-theme-alpine .ag-header-row-column-group {
    max-height: 24px !important;
  }

  .ag-header-row-column-group .ag-header-group-cell {
      &:nth-of-type(2) { background: #f5f5f5; }
      &:nth-of-type(3) { background: #ececec; }
  }

  .ag-pivot-off {
    height: 73px !important;
    min-height: 73px !important;
  }

  .ag-theme-alpine .ag-row-selected {
    background-color: rgba(68, 28, 193, 0.4) !important;
  }

  /* .ag-theme-alpine .ag-ltr .ag-cell-focus {
     background-color: rgba(68,28,193,0.4);
   }*/
  .ag-theme-alpine .ag-header-cell {
    padding-left: 5px;
    padding-right: 5px;
  }

  .ag-header-group-cell-label, .ag-header-cell-label {
    text-align: center;
    display: grid;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: 300px;
  }

  .ag-overlay {
    position: relative;
  }

  &.full {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  &.closed {
    transition: all 0.35s ease-in-out;
    max-height: 40px;
    max-width: 40px;
    margin: 10px;
    overflow: hidden;

    .header {
      width: 40px;

      h5, .tabs {
        display: none;
      }
    }

    .ReactTable {
      transition: all 0.35s ease-in-out;
      height: 0 !important;
      margin: 0;
      border: none;
      overflow: hidden;
    }
  }

  button{
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    cursor: pointer;
    i {
      font-size: 18px;
    }

    &:hover {
      color: $primary-theme;
    }
  }

  .header {
    display: flex;
    height: 40px;
    background-color: #ffffff;

    h5 {
      margin: 0 10px 0 5px;
      font-weight: bold;
      font-size: 18px;
      align-self: center;
    }

    .tabs {
      align-self: flex-end;

      .tab {
        display: flex;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        font-size: 10px;
        text-align: center;
        align-self: flex-end;

        p {
          $t: background-color .15s, color .15s;

          margin: 0 .5em;
          padding: 8px 10px;

          color: #777;

          background-color: rgba(235, 235, 235, 0.3);
          cursor: pointer;
          transition: $t;

          &:hover {
            background-color: rgba(200, 200, 200, .2);
            transition: $t;

            &:not(.active) {
              color: #666;
              transition: $t;
            }
          }

          &.active {
            color: $primary-theme;
          }
        }
      }
    }
  }


  .ag-root-wrapper {
    font-family: $Korolev;

    .ag-react-container {
      height: 100%;
      input{
        width: 100%;
      }
    }

    .ag-header {
      background-color: #ffffff;
      border-bottom: 1px solid #aaa;

      .ag-header-cell-label {
        padding: 5px;
        font-size: 1.2rem;
        letter-spacing: .1rem;
        color: #3a3a3a;
        font-weight: 700;
      }
    }

    .ag-body-viewport {
      height: initial;
    }

    .ag-overlay-no-rows-center {
      font-family: $OpenSans;
      font-weight: 400;
      font-size: 1.2rem;
      padding: 20px;
      background-color: #ffffff;
    }

    .ag-row {
      background-color: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      &.ag-row-hover {
        background-color: $wash-hover;
      }


      &.ag-row-not-inline-editing {
        .TableCell {
          white-space: normal;
          height: 100%;
          padding: 10px 5px;

          &.active {
            background-color: $wash-hover;
          }

          &.selected {
            background-color: rgba(68, 28, 193, 0.4);
          }

          span {
            padding: 5px 10px;
          }
        }
      }
    }

    .ag-cell {
      font-family: $OpenSans;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.75;
      padding-left: 0!important;
      padding-right: 0!important;
      border-right-width: 0 !important;
      border-left-width: 0 !important;

      &.ag-cell-not-inline-editing {
        .TableCell {
          white-space: normal;
          height: 100%;
          padding: 10px 5px;

          &.active {
            background-color: $wash-hover;
          }

          &.selected {
            background-color: rgba(68, 28, 193, 0.4);
          }

          span {
            padding: 5px 10px;
          }
        }
      }
    }
  }


  .ReactTable {
    transition: all 0.35s ease-in-out;

    .rt-td {
      padding: 0;
    }

    .rt-thead {
      margin-right: 17px;

      &.-header {
        box-shadow: none;
      }
    }

    .rt-tbody {
      overflow-y: scroll;
      background-color: $wash;

      .rt-tr-group {
        cursor: pointer;
        background-color: #ffffff;
      }
    }

    .rt-tr-group {
      flex: none;
    }

    .TableCell {
      width: 100%;
      height: 100%;
      padding: 10px 5px;

      input {
        width: 100%;
        height: 100%;
      }

      &.editing {
        padding: 0;
      }

      span {
        padding: 5px 10px;
      }

    }
  }
}

