.Bar {
    height: 100%;
    overflow: hidden;

    &__graphic {
        display: inline-block;

        height: 100%;
        padding: 0;
        margin: 0;

        background: #7FAD96;
        transform: translateZ(0);
    }
}
