.Shape {
    position: relative;

  &__info {
      pointer-events: none;

      .Shape__info-inner {
          text-align: center;
          background: #FFFFFF;
          border-radius: 3px;
          border: 1px solid #EEEEEE;
          z-index: 99999;
          font-size: 10px;
        opacity: 0;
        top: 5px;
        transition: top .15s, opacity .1s;
        padding: 5px 0;
        box-shadow: 0 0 8px rgba(20,20,20,.5);

        p {
            margin: .5em;
        }
      }
  }

  &--active {
      .Shape__info-inner {
        top: 0;
        opacity: 1;
        transition: top .2s, opacity .15s;
      }
  }

  rect, circle, polygon {
    stroke-width: 2px;
    stroke: white;
  }

  .object {
    &.selected {
      stroke: $nd-theme;
      &.warning {
        stroke: $error;
      }
    }

    &.active {
      stroke: $primary;
    }

    &.warning {
      stroke: lighten($error, 25%);
      &.active {
        stroke: mix($primary, $error);
      }
    }
  }

  .label {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
    word-break: break-all;

    .label-text {
      text-align: center;
      white-space: pre-wrap;
      word-break: keep-all;
      width: 100%;
      height: 100%;
      position: relative;

      .label-text-inner {//https://www.w3.org/Style/Examples/007/center.en.html
        position: absolute;
        top:50%;
        left:50%;
        width: 100%;
        transform: translate(-50%, -50%);
        .label-name {
          padding: 0.3em 0.1em;
          font-size: 1.2em;
        }

        .label-area {
          padding: 0 0;
          font-size: 1.3em;
        }
      }
    }

    textarea {
      background-color: rgba(68, 28, 193, 0.1);
      border: none;
    }
  }
}
