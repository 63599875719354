.Stage{
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  z-index: 101;

  &.shape{
    cursor: crosshair;
  }

  svg > rect {
    pointer-events: auto !important;
  }

  &.shape, &.scale{
    svg{
      cursor: crosshair !important;
    }
  }

  svg{
    cursor: default !important;
    g{
      pointer-events: initial !important;
    }
  }

  .DrawArrow{
    pointer-events: none;
    stroke-width: 2px;
    stroke: black;
  }
  .DrawArrowOrigin, .DrawArrowTarget{
    pointer-events: none;
  }
}
