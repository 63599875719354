.Login {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  div{
    align-self: center;
  }
  img{
    border-radius: 40px;
  }
  h1{
    font-family: $Korolev;
    font-weight: 700;
  }
  p{
    font-family: $Korolev;
    font-weight: 200;
  }

}
