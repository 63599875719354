@import "src/theme";
.Toolbar.vt-tb{
  margin: 0 0 0 10px;
  position: relative;
  pointer-events: all;
  grid-column: 1;
  grid-row: 2;
  z-index: 103;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(5, 0, 56, 0.25);
  background-color: #ffffff;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  .sq:first-child{
    height: initial;
    padding-top: 0;
    border-radius: 5px 5px 0 0;
  }
  .sq:last-of-type{
    border-radius: 0 0 5px 5px;
  }
  button{
    background-color: #ffffff;
    cursor: pointer;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #E3E3E3;
    border-radius: 0;
    color: #050038;
    i{
      font-size: 20px;
      padding: 8px;
    }
    &.disabled{
      pointer-events: none;
      i{
        color: #cccccc;
      }
    }
    &.enabled i{
      font-weight: bold;
    }
    &.active{
      font-weight: bold;
      color: $primary-theme;
      background-color: $nl;
      &:hover{
        background-color: $wash-hover;
      }
      .shape{
        border-color: $primary;
      }
    }
    &:focus {
      outline: none;
    }
    &:hover{
      i{
        color: $primary-theme;
      }
      div{
        border-color: $primary;
      }
    }
  }
}
