@import "src/theme";

.modal.export-modal {
  width: 70%;
  max-width: 500px;
  min-width: 400px;
  border-radius: 5px;
}

.ExportModal {
  text-align: left;
  .content{
    margin: 10px 40px;
  }
  .top-row {
    margin-left: -10px;

    .title{
      display: inline-block;
    }
    .icon {
      display: inline-block;
      font-size: 2.4rem;
      padding: 0 10px;
    }
  }


  p {
    font-size: 14px;
  }

  .exportButtons {
    display: flex;

    .cancelButton {
      display: block;
      max-width: 80px;
      margin: 20px 0 10px 0;
    }

    .exportButton {
      display: block;
      margin: 20px 0 20px 60px;
    }
  }

  .format {
    display: inline-block;
    .export-options{
      display: inline-grid;
      padding: 15px 0;
    }
  }

  .format fieldset {
    border: none;
  }

  .title-text {
    margin-top: 20px;
    @include h5;
  }

  .sub-title-text {
    padding: 5px 0;
  }

  .format .export-options label {
    text-transform: none;
    line-height: 20px;
    display: block;
    border: none;
    padding: 5px;
    margin-left: -10px;

    .maintext {
      font-size: 1.2em;
    }

    .subtext {
      font-size: 1.1em;
      display: block;
      margin-left: 20px;
      font-style: italic;
    }
  }

  .format fieldset label span {

    margin-left: 5px;
  }

}