.Tooltip{
    background-color: white;
    padding: 5px 5px 5px 15px;
    position: absolute;
    z-index: 999;
    width: 150px;
    height: auto;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  p{
    margin: 0;
  }
}
