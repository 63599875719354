.SelectionBox{
  .Handle{
    &.mid{
      cursor: grab;
    }
    &.nw{
      cursor: nw-resize;
    }
    &.ne{
      cursor: ne-resize;
    }
    &.sw{
      cursor: sw-resize;
    }
    &.se{
      cursor: se-resize;
    }
  }
  .rotationHandle{
    cursor: all-scroll;
    font-size: 20px;
    div{
      width: 100%;
      height: 100%;
    }
  }
  .arrowHandle{
    filter: url(#shadow);
  }
}

