@import "src/theme";
.modal.settings-modal {
    width: 90%;
    max-width: 1000px;
    min-width: 400px;
    border-radius: 5px;
}

.ProjectSettings {
    width: 100%;

    .settings-row {
        display: flex;
    }

    .settings-column {
        flex: 1 1 40%;
        margin: 0 5%;
    }

    .settings-group + .settings-group {
        margin-top: 9em;
    }

    h2 {
        width: 100%;
        padding: 0 0 .4em;
        margin-bottom: 1.2em;
        text-transform: uppercase;
        color: $primary-theme-large;
        font-size: 18px;

        border-bottom: 2px solid $primary;
    }

    h4 {
        font-size: 14px;
        margin: 0 0 10px 0;
        em {
            font-weight: 400;
            font-style: normal;
        }
    }

    .UnitInput + h4 { margin-top: 1em; }
    .input-group + h4 { margin-top: 1em; }

    .buttons {
        margin: 3em 5%;
        text-align: right;

        button:first-of-type {
            float: left;
        }
    }

    select {
        display: block;
        width: 100%;
        height: 3em;
        padding: 5px;

        color: $primary-theme;
        font-size: 14px;
        border-radius: 5px;
        background: #ffffff;
        border: 1px solid $nl;
        option{
          font-family: $OpenSans;
          text-transform: uppercase;
        }
    }
}

.input-group {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  .UnitInput {
    margin-bottom: 2px;
  }
}

.UnitInput {
    display: flex;
    font-size: 14px;
    background: #ffffff;

    &.currency{
      input{
        order: 2;
        border-radius: 0 5px 5px 0;
      }
      label{
        order: 1;
        border-radius: 5px 0 0 5px;
      }
    }

    &--right{
      input{
        border-radius: 5px 0 0 5px;
      }
      label{
        border-radius: 0 5px 5px 0;
      }
    }

    &--left{
      input{
        border-radius: 0 5px 5px 0;
      }
      label{
        border-radius: 5px 0 0 5px;
      }
    }

    input {
        width: 100%;
        padding: 5px;
        color: $primary-theme;
        background: none;
        border: 1px solid $nl;

        &:focus{
          border: 1px solid #000000;
          outline: none;
        }
    }

    label {
        white-space: nowrap;
        display: inline-block;

        border: 1px solid $nl-focus;
        padding: 10px 20px;
        background-color: $nl-focus;
        color: $nd;
    }

    &--left {
        flex-direction: row-reverse;
    }

    &--full {
      width: 100%;
    }

    &--half {
      width: 48%;
      display: inline-flex;
      flex-shrink: 0;
      & input {
        flex-grow: 10;
        flex-shrink: 0;
        width: inherit;
      }
      & label {
        flex-grow: 0;
      }
    }
}
