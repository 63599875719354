.UndoMenu{
  position: relative;
  pointer-events: all;
  display: flex;
  z-index: 99;
  white-space: nowrap;
  .container{
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    border-radius: 5px;
    display: flex;
    justify-items: center;
  }
  button{
    width: 40px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    margin: 8px 0;
    &.disabled{
      cursor: default;
      color: #d9d9d9;
      background-color: transparent;
    }
    &:focus {
      outline: none;
    }
    &:hover{
      color: #441cc1;
    }
    &:last-child{
      border-radius: 0 5px 5px 0;
      border-left: 1px solid #cccccc;
    }
  }
}
