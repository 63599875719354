.LoadImageDialog{
  text-align: center;
  .ss-button.block{
    margin: 30px 0 0 0;
    max-width: none;
  }
  p{
    font-style: italic;
    &.error{
      color: $error;
    }
  }
  label{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input{
    display: none;
  }
  .image{
    font-size: 14px;
    margin: 20px 0 0 0;
    span{
      margin: 0 5px 0 0;
      text-decoration: underline;
    }
    .remove{
      cursor: pointer;
      color: red;
    }
  }
  .buttons{
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
  }
  .content{
    margin: 20px;
  }
}
