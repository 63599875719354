.FillBar {
    display: block !important;

    &:first-of-type { margin-top: 24px !important; }

    color: #aaaaaa;

    label {
        display: block;
        margin-bottom: 5px;
        font-size: 10px;
    }

    .Bar {
        background: #dddddd;
    }

    & > .BarChart__graphic {
        display: flex;
        flex: 0 0;

        & > .Bar {
            flex: 1 0 80% important;
            height: 10px;
        }
    }

    .FillBar__value {
        flex: 1 0 20%;
        text-align: right;
    }

}
