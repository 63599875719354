/**
 * Variables
 */

$dashboard-inner-width: 470px;


/**
 * Imports
 */

@import './Login';
@import './MenuBar';
@import './Stage/SelectionBox';
@import './Stage/DrawCircle';
@import './Stage/Shape';
@import './Stage/Stage';
@import './Stage/Arrow';
@import './Stage/Tooltip';
@import './Toolbar';
@import './UndoMenu';
@import './ViewToggle';

@import './Dashboard/DashboardView';
@import './Dashboard/charting/Bar';
@import './Dashboard/charting/FillBar';
@import './Dashboard/charting/ClampedBar';
@import './Dashboard/charting/StackedBar';

@import './Table/TabularView';
@import './Table/Autocomplete';
@import './Table/SpreadsheetPaste';

@import './Modal';
@import './SaveDialog';
@import './LoadImageDialog';
@import './ScaleBackgroundDialog';
@import './IntroDialog';
@import './ProjectSettings';
@import './ExportModal';

@import './CopyModal';
@import './ActiveUsers';
@import './LoadModal';

