.IntroModal{
  text-align: center;
  color: #050038;
  .content{
    margin: 5em;
  }
  .new{
    padding: 2em 5em;
    border-top: 5px solid #EBEBEB;
    h2{
      text-transform: uppercase;
      font-weight: bold;
      font-family: $Korolev;
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
    p{
      font-style: normal;
      text-align: left;

      &.error {
          color: #d00;
          margin-top: 15px;
          padding-top: 15px;

          border-top: 1px solid red;
      }
    }
  }
  p{
    font-size: 14px;
    font-style: italic;
    margin: 0;
    &.copy{
      margin: 20px 0 0;
      text-align: left;
      font-style: normal;
    }
  }
  span{
    font-size: 18px;
    text-transform: uppercase;
    font-family: $Korolev;
    font-weight: bold;
    color: #D9D9D9;
  }
  .startButton{
    display: block;
    margin: 40px auto 0;
    &.nl{
      color: #050038;
    }
  }
  .LoadDialog{
    padding: 0;
    max-width: 250px;
    width: 100%;
    margin: 0 auto 20px;
    border: none;
    background-color: transparent;
    display: block;
    label {
      font-size: 1.6rem;
      display: flex;
      text-align: center;
      align-items: center;
      text-transform: uppercase;
      font-family: $Korolev;
      transition: background-color 0.15s ease-in-out;
      font-weight: 700;
      background-color: #ebebeb;
      cursor: pointer;
      border-radius: 3px;
      color: #3a3a3a;
      height: 40px;
      padding: 0 15px;
      border: none;
      margin: 0;
      span{
        width: 100%;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: #E3E3E3;
      }
    }
    input{
      display: none;
    }
  }
}

.modal.intro{
  max-width: 70em;
  border-radius: 5px;
}
