@import "src/theme";
.ViewToggle{
  position: relative;
  margin: 0;
  pointer-events: all;
  justify-self: end;
  z-index: 101;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  display: flex;
  grid-column: 4;
  grid-row: 1;
  button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #ffffff;
    border: none;
    color: $nd-theme;
    p{
      font-family: $Korolev;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
    }
    &.active{
      font-weight: bold;
      color: $primary-theme;
      &:hover{
        color: $primary-theme-hover;
      }
    }
    &:focus {
      outline: none;
    }
    &:hover{
      color: $primary-theme;
      cursor: pointer;
    }
    &:last-child{
      border-radius: 0 5px 5px 0;
      border-left: 1px solid #cccccc;
    }
  }
}
