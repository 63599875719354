.StackedBar {
    & > .BarChart__graphic {
        flex: 1 0 60% !important;
    }

    .StackedBar__value {
        flex: 1 0 20%;
        text-align: right;
    }

    .Bar {
        height: 20px;
    }
}
