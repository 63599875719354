.ClampedBar {
    position: relative;
    display: block !important;

    .StackedBar__value {
        color: $success;
    }

    &.out-of-range {
      .StackedBar__value {
        color: $error;
      }
    }

    &.presentationMode{
      .StackedBar__value {
        color: black !important;
      }
    }

    .clamp .Bar {
        height: 10px;
        width: 60%;
        margin: auto;

        background: #aaaaaa;
    }

    .match-target {
        position: absolute;
        top: -110%;
        right: 0;

        padding: 5px 8px;

        color: #696969;
        font-size: 11px;

        background: #d9d9d9;
        border-radius: 5px;
    }

    .StackedBar .Bar {
        background: none;
        overflow: visible;
        white-space: nowrap;
    }
}
