@import "src/theme";
.DashboardView {
  grid-row-start: 1;
  grid-row-end: 5;
  grid-column-start: 4;
  grid-column-end: 5;

  margin: 10px 10px 10px 5px;
  z-index: 102;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: $br;
  padding: 10px;
  overflow: hidden;

  justify-self: end;
  transition: all 0.35s ease-in-out;
  max-height: 10000px;
  max-width: 10000px;
  button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0 3px;
    i{
      font-size: 2rem;
    }
    &:hover{
      color: $primary-theme;
    }
  }

  svg path{
    stroke-width: 0;
  }

  &.closed{
    transition: all 0.35s ease-in-out;
    max-height: 32px;
    max-width: 32px;
    padding: 5px 0 0 3px;
  }

  .header{
    display: flex;
    width: 470px;
    h5{
      margin: 0 1rem;
      line-height: 23px;
      font-size: 18px;
      color: #050038;
      font-weight: bold;
    }
  }
  p.p-lg{
    span{
      font-weight: 700;
    }
  }

  .scrollbox {
    position: relative;
    left: 7px;
    width: calc(100% + 15px);
    height: 100%;
    overflow-y: scroll;
    input{
      vertical-align: middle;
    }
  }

  .record {
      margin-top: 16px;

      h5 { margin-bottom: 8px; }

      .StackedBar__value {
          color: $success;
      }

      &.out-of-range {
        .square-gross,
        .StackedBar__value {
            color: $error;
        }
      }

      &.presentationMode{
        .square-gross{
          color: #000000;
        }
      }
  }

  .record__info {
    display: flex;
    width: 100%;
    margin-top: 8px;

    & > * {
        flex: 1 0 33.33%;
    }
  }

    .BarChart {
        display: flex;

        width: 100vw;
        max-width: $dashboard-inner-width;

        & + .BarChart { margin-top: 24px; }

        & > label {
            flex: 1 0 20%;
        }

        & > .BarChart__graphic {
            position: relative;
            flex: 1 0 80%;
        }

        &__value {
            position: relative;
            z-index: 2;

            padding: 5px;
            background: rgba(255,255,255,.3);
        }
  }
}

.SummaryView {
    .clamp {
        display: none;
    }

    padding-bottom: 50px;

    .summary-group {
        background-color: #f9f9f9;
        padding: 10px;
        margin: 32px 10px 0 0;

        .header{
          display: flex;
          justify-content: space-between;

          h5{
            margin: 0;
          }
          button{
            width: 20px;
            height: 20px;
            outline: none;
            font-size: 20px;
          }
        }
        .BarChart {
            margin-top: 8px;
        }
    }
}

.DepartmentView {
    .FillBar {
        label {
            display: block;
            flex: 0;
            width: 100%;
        }
    }
}

.SelectionView {
    .target-group {
        margin-bottom: 2rem;
    }

    p.primary {
        color: $primary-theme;
    }

    button {
        position: absolute;
        top: -.5rem;
        right: 30%;

        padding: 5px 8px;

        color: #696969;
        font-size: 11px;

        background: #d9d9d9;
        border-radius: 5px;
    }
}
